const PubSub = window.PubSub;
const $ = window.jQuery;

class TieredTabs {
	constructor() {
		this.prevWidth = null;

		PubSub.subscribe('ready', this.init.bind(this));
		PubSub.subscribe('draw', this.draw.bind(this));
	}
	init() {
		var self = this;

		this.tieredTabs = $('.component.tiered-tabs').each(function(index, elem) {
			var tieredTab = $(elem);
			var tabContainers = tieredTab.find('.tiered-tabs-container');
			var shadowContainer = tieredTab.find('.shadow-container');
			var tabs = tabContainers.find('.tab');

			shadowContainer.html(tabContainers.html());

			tabs.each(function(index, elem) {
				var tab = $(elem);
				var label = tab.find('.tab-content-title');
				var content = tab.find('.tab-content-row');

				if (index == 0) {
					tab.removeClass('inactive').addClass('active');
				}

				var subtabs = content.find('.subtabs');
				var subtabContents = content.find('.subtabs .subtab-content');
				var subtabButtons = content.find('.subtab-buttons a.button');
				var subtabMobileButtons = content.find('.subtabs .mobile-subtab.button');
				var learnMoreUrl = content.find('a.button.cta').attr('href');

				var activate = function() {
					if (self.prevWidth < 768) {
						if (tab.hasClass('expanded')) {
							tab.removeClass('expanded');
							content.slideUp();
						} else {
							tab.addClass('expanded');
							content.slideDown();
						}
					} else {
						tabs
							.removeClass('active')
							.addClass('inactive');

						tab
							.removeClass('inactive')
							.addClass('active');
					}
					subtabs.removeClass('active');					
				};

				label.on('click', function(event) {
					activate();

					if (self.prevWidth >= 768 && learnMoreUrl) {
						document.location.href = learnMoreUrl;
					}
				}).on('mouseenter', function(event) {
					if (self.prevWidth < 768)
						return;

					waitReadySetGo(333, (d) => {
						d.offHandler = () => {
							d.cancel();
						};
						label.on('mouseleave', d.offHandler);
					}, (d) => {
						activate();
					}, (d) => {
						label.off('mouseleave', d.offHandler);
					})
				});

				subtabMobileButtons.each(function(index, elem) {
					var button = $(this);
					var index = button.attr('data-subtab-index');
					var content = subtabContents.filter(`[data-subtab-index="${ index }"]`);

					button.on('click', function(event) {
						if (button.hasClass('expanded')) {
							button.removeClass('expanded');
							content.slideUp();
						} else {
							button.addClass('expanded');
							content.slideDown();
						}
					});
				});

				subtabButtons.each(function(index, elem) {
					var button = $(this);
					var index = button.attr('data-subtab-index');
					var content = subtabContents.filter(`[data-subtab-index="${ index }"]`);

					var openSubtab = function(event) {
						subtabs
							.addClass('active');

						subtabButtons
							.removeClass('active').addClass('inactive')
							.filter(`[data-subtab-index="${ index }"]`)
							.addClass('active').removeClass('inactive');

						subtabContents
							.removeClass('active').addClass('inactive')
							.filter(`[data-subtab-index="${ index }"]`)
							.addClass('active').removeClass('inactive');
					};

					button.on('click', function(event) {
						openSubtab(event);
					});
				});
			});
		});

		setInterval(() => { this.prevWidth = 0 }, 500);
	}
	draw(topic, args) {
		if (args && args.width && this.prevWidth != args.width) {

			// Going to Desktop
			if (this.prevWidth < 768 && args.width >= 768) {
				this.tieredTabs && this.tieredTabs.each(function(index, elem) {
					var tieredTab = $(elem);

					tieredTab
						.find('.subtab-content, .tab-content-row')
						.css('display', '');
				});
			}

			// Going to Mobile
			if (this.prevWidth >= 768 && args.width < 768) {
				this.tieredTabs && this.tieredTabs.each(function(index, elem) {
					var tieredTab = $(elem);

					tieredTab.find('.expanded').removeClass('expanded');
					
					tieredTab
						.find('.left, .left-content, .subtabs, .subtab-content')
						.css('min-height', '')
						.attr('minRowHeight', '');

					tieredTab
						.find('.tiered-tabs-container')
						.css('min-height', '');

					tieredTab
						.find('.tab-content-row')
						.css('margin-top', '');
				});
			}


			this.prevWidth = args.width;

			if (args.width < 768) {

			} else {
				this.tieredTabs && this.tieredTabs.each(function(index, elem) {
					var tieredTab = $(elem);
					var minCompHeight = 0;
					var tabContainer = tieredTab.find('.tiered-tabs-container');
					var shadowContainer = tieredTab.find('.shadow-container');

					var tabRows = tabContainer.find('.tab-content-row');
					var shadowRows = shadowContainer.find('.tab-content-row');

					var titleHeight = shadowContainer.find('.component-title').outerHeight();

					shadowRows.each(function(index, elem) {
						var minRowHeight = 0;
						var leftHeight = $(elem).find('.left').outerHeight();
						var rightHeight = $(elem).find('.subtab-content').max(function() { return $(this).outerHeight(); });

						minRowHeight = 	leftHeight > rightHeight ? leftHeight : rightHeight;
						minCompHeight = minRowHeight > minCompHeight ? minRowHeight : minCompHeight;

						tabRows.eq(index).find('.left, .left-content, .subtabs, .subtab-content').each(function(index, elem) {
							var element = $(elem);
							var prevMinHeight = element.attr('minRowHeight');

							if (parseInt(prevMinHeight) != parseInt(minRowHeight)) {
								element.css('min-height', minRowHeight);
								element.attr('minRowHeight', minRowHeight);
							}
						});
					});

					tabContainer.find('.tab-content-row').css('margin-top', titleHeight);
					tabContainer.css('min-height', (minCompHeight < (550 - titleHeight)) ? 550 : minCompHeight + titleHeight);
				});			
			}
		}
	}
}

export default new TieredTabs();